import FilterListIcon from '@mui/icons-material/FilterList';
import {Chip, Grid, IconButton, Typography} from '@mui/material';
import {
  AllMIDs,
  SearchCriteria,
  useApiContainer,
  useAuthContainer,
  useMidsContainer,
} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {
  ActiveFilter,
  ExportProps,
  Filters,
  FilterUpdate,
  Loading,
  MessageReasonCodes,
  Square,
  Table,
} from '@ozark/common/components';
import {Authorization} from '@ozark/functions/src/functions/express/private/types';
import {getEntryModeByCode} from '@ozark/functions/src/functions/express/private/types/Authorizations';
import {PaginatedAuthorizationsResponse} from '@ozark/functions/src/functions/express/private/types/PaginatedResponse';
import {addSeconds} from 'date-fns';
import {format, utcToZonedTime} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useUrlQuery} from '../../../hooks/useUrlQuery';
import {useQueryMonthYear} from '../../Analytics/common/useQueryMonthYear';
import {CardImage, CardImagesDictionaryType} from '../../CardImage';
import {Bar, Filter, MidOptionPreselected} from '../common';
import {forceMtdActiveFilter} from '../common/Utils/forceMtdActiveFilter';
import {getFiltersMidFirst} from '../common/Utils/getFiltersMidFirst';
import {getAmountSelector} from '../helpers';
import {
  currentFormatter,
  forceActiveFilter,
  numberFormatter,
  useReportingPageStyles,
} from '../Reporting';
import {TimeZonedDate} from './timezone/TimeZonedDate';
import {TimeZoneInfo, TimeZoneTitle, useTimeZoneByMerchant} from './timezone/useTimeZoneByMerchant';
import {AuthorizationFilters, AuthorizationResponses, DATETIME_FORMAT, DATE_FORMAT} from './Types';

const DefaultCriteria: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: 'transactionDate',
};

export const Authorizations = ({midOptionPreselected}: MidOptionPreselected) => {
  const classes = useReportingPageStyles();
  const {authUser, claims} = useAuthContainer();
  const {year, month} = useQueryMonthYear();
  const {mids, midsOptions, selectedMid, forceSelectMidIfNeeded, handleSelectMid} =
    useMidsContainer();
  const {timeZoneInfo, setTimeZone, merchantTimeZoneExists} = useTimeZoneByMerchant(
    selectedMid,
    authUser?.data?.uid,
    claims
  );
  const [authorizations, setAuthorizations] = useState<PaginatedAuthorizationsResponse | null>();
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DefaultCriteria);
  const [filters, setFilters] = useState<Filter>({});
  const [isAuthorizationsFetching, setIsAuthorizationsFetching] = useState<boolean>(true);
  const query = useUrlQuery();

  const api = useApiContainer();

  const columnsConfig = getColumnsConfig(
    timeZoneInfo,
    setTimeZone,
    merchantTimeZoneExists,
    selectedMid
  );

  const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  });

  const getAllDataForExport = useCallback(async () => {
    if (!api) return [];

    const pageConfigFull: SearchCriteria = {...searchCriteria, offset: 0, limit: 0};
    const result = await api?.transactions.getAuthorizations(
      pageConfigFull,
      selectedMid,
      Object.values(filters)
    );
    return result?.data ?? [];
  }, [searchCriteria, selectedMid, filters]);

  const exportProps: ExportProps = {
    filename: 'authorizations-report',
    rows: authorizations?.data,
    columnsConfig,
    getRows: getAllDataForExport,
  };

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const setFiltersAndResetOffset = (_filters: Filter | null, filterUpdate?: FilterUpdate) => {
    if (filterUpdate) {
      setFilters(filters => getFiltersMidFirst(filters, filterUpdate));
    } else {
      setFilters(_filters ?? {});
    }
    setSearchCriteria({...searchCriteria, offset: 1});
  };

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFiltersAndResetOffset(_filters);
  };

  const forceFilterByMid = (mid: string) => {
    const forcedFilter = forceActiveFilter(AuthorizationFilters, 'mid', '__eq', mid);
    setFiltersAndResetOffset(null, {updateColumnName: 'mid', filter: forcedFilter});
  };

  const forceFilterByMtd = () => {
    const forcedFilter = forceMtdActiveFilter(AuthorizationFilters);
    setFiltersAndResetOffset(null, {updateColumnName: 'transactionDate', filter: forcedFilter});
  };

  const onMidSelect = (mid: string) => {
    if (mid !== selectedMid) {
      handleSelectMid(mid);
      forceFilterByMid(mid);
    }
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    const _filters = {...filters};
    if (filter.option.column === 'authorizationStatus') {
      delete _filters['authorizationStatus'];
    }
    setFiltersAndResetOffset({..._filters, [filter.option.column]: filter});
  };

  const getAuthorizations = () => {
    api?.transactions
      .getAuthorizations(searchCriteria, selectedMid, Object.values(filters))
      .then(response => {
        setAuthorizations(response);
        setIsAuthorizationsFetching(false);
      });
  };

  useEffect(() => {
    forceFilterByMtd();
  }, []);

  useEffect(() => {
    if (selectedMid === AllMIDs) return;

    forceFilterByMid(selectedMid);
  }, [selectedMid]);

  useEffect(() => {
    if (mids.promised || !mids.data) return;
    if (midOptionPreselected) {
      handleSelectMid(midOptionPreselected.mid);
    } else {
      forceSelectMidIfNeeded();
    }
  }, [mids, midOptionPreselected, forceSelectMidIfNeeded]);

  useEffect(() => {
    if (isEmpty(filters)) return;
    getAuthorizations();
  }, [searchCriteria, filters]);

  useEffect(() => {
    const authorizationFilterKeys = AuthorizationFilters.map(af => af.id);
    for (const key of query.keys()) {
      if (!authorizationFilterKeys.includes(key)) continue;
      const authorizationFilter = AuthorizationFilters.find(f => f.id === key);
      if (!authorizationFilter) continue;
      if (key === 'mid') {
        const mid = query.get(key);
        if (mid) {
          onMidSelect(mid);
        }
      } else {
        handleApplyFilter({
          option: authorizationFilter,
          operator: authorizationFilter.operators[0],
          value: query.get(key) || null,
        });
      }
    }
    if (!!year && !!month) {
      const authorizationFilter = AuthorizationFilters.find(f => f.id === 'dateRange');
      if (!authorizationFilter) return;
      handleApplyFilter({
        option: authorizationFilter,
        operator: authorizationFilter.operators[0],
        value: [new Date(year, month - 1, 1), addSeconds(new Date(year, month, 1), -1)],
      });
    }
  }, [year, month]);

  if (mids.promised || !mids.data || isAuthorizationsFetching) return <Loading />;

  return (
    <div className={classes.root}>
      <Bar
        title="Authorizations"
        midsLoaded
        mids={midOptionPreselected ? [midOptionPreselected] : midsOptions}
        selectedMid={selectedMid}
        onMidSelect={onMidSelect}
        Filters={<Filters options={AuthorizationFilters} onApplyFilter={handleApplyFilter} />}
        exportProps={exportProps}
      />
      {isEmpty(mids.data) && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}
      {authorizations && isEmpty(authorizations) && (
        <Typography className={classes.noContent}>No Authorizations</Typography>
      )}
      {authorizations && !isEmpty(authorizations) && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            {filters && !isEmpty(filters) && (
              <IconButton disabled size="large">
                <FilterListIcon />
              </IconButton>
            )}
            {filters &&
              Object.keys(filters).map(key => {
                const filter = filters[key];
                if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, DATETIME_FORMAT)}</b>' and '
                          <b>{format(filter.value?.[1] as Date, DATETIME_FORMAT)}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'date' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, DATE_FORMAT)}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'list' && filter.option.options) {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{filter.option.options.find(x => x.key === filter.value)?.value}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                return (
                  <Chip
                    key={`${key}-${filter.operator.id}`}
                    className={classes.chip}
                    label={
                      <span>
                        <b>{filter.option.label}</b> {filter.operator.label} '
                        <b>
                          {filter.option.type === 'currency'
                            ? `$${filter.value}`
                            : `${filter.value}`}
                        </b>
                        '
                      </span>
                    }
                    variant="outlined"
                    onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                  />
                );
              })}
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Total Authorizations Count': numberFormatter.format(
                  authorizations.totalAuthorizationCount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={3}>
            <Square
              lines={{
                'Total Approved Authorizations Count': numberFormatter.format(
                  authorizations.totalApprovedAuthorizationCount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={3}>
            <Square
              lines={{
                'Total Declined Authorizations Count': numberFormatter.format(
                  authorizations.totalDeclinedAuthorizationCount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Approved Ratio by Count': percentFormatter.format(
                  authorizations.approvedRatioByCount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Approved Ratio by Amount': percentFormatter.format(
                  authorizations.approvedRatioByAmount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Total Authorization Amount': currentFormatter.format(
                  authorizations.totalAuthorizationAmount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={3}>
            <Square
              lines={{
                'Total Approved Amount': currentFormatter.format(
                  authorizations.totalApprovedAmount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={3}>
            <Square
              lines={{
                'Total Declined Amount': currentFormatter.format(
                  authorizations.totalDeclinedAmount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Declined Ratio by Count': percentFormatter.format(
                  authorizations.declinedRatioByCount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={2}>
            <Square
              lines={{
                'Declined Ratio by Amount': percentFormatter.format(
                  authorizations.declinedRatioByAmount
                ),
              }}
              center
            />
          </Grid>

          <Grid item xs={12}>
            <Table
              stickyHeader
              scrollableBody
              customHeight="75vh"
              columns={columnsConfig}
              data={authorizations}
              onRetrieveData={handleRetrieveData}
              paginate
              getCellProps={column => ({
                sx: [
                  column.id === 'authorizationResponse' && {
                    maxWidth: '250px',
                  },
                ],
              })}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const getFormattedDisplayStatus = (row: Authorization): string => {
  if (row.messageReasonCode) {
    return MessageReasonCodes[row.messageReasonCode];
  }
  return row.authorizationResponse ? AuthorizationResponses[row.authorizationResponse] : 'Declined';
};

const getFormattedTransactionDate = (transactionDate: string, timeZoneId: string) => {
  const date = new Date(transactionDate);
  const localTime = utcToZonedTime(date, timeZoneId);
  return format(localTime, DATETIME_FORMAT);
};

const getColumnsConfig = (
  timeZoneInfo: TimeZoneInfo,
  setTimeZone: (timeZoneName: TimeZoneTitle) => void,
  merchantTimeZoneExists: boolean,
  selectedMid?: string
): Column<Authorization>[] => [
  {
    id: 'cardType',
    numeric: false,
    sortable: true,
    label: 'Card',
    selector: row => (
      <CardImage cardType={row.cardType} dictionaryType={CardImagesDictionaryType.Authorizations} />
    ),
    export: true,
  },
  {
    id: 'bin',
    label: 'BIN',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'number',
    numeric: false,
    sortable: false,
    label: 'Number',
    selector: row => `${row.accountNumberFirst6}******${row.accountNumberLast4}`,
    export: row => `${row.accountNumberFirst6}******${row.accountNumberLast4}`,
  },
  {
    id: 'authorizedAmountSigned',
    numeric: true,
    sortable: true,
    label: 'Amount',
    selector: (row: Authorization) => getAmountSelector(row.authorizedAmountSigned),
    export: true,
  },
  {
    id: 'approvalCode',
    numeric: false,
    sortable: true,
    label: 'Approval Code',
    export: true,
  },
  {
    id: 'terminalNumber',
    numeric: false,
    sortable: true,
    label: 'TID',
    export: true,
  },
  {
    id: 'entryMode',
    numeric: false,
    sortable: true,
    label: 'Entry Mode Code',
    export: true,
  },
  {
    id: 'entryModeDescription',
    numeric: false,
    sortable: false,
    label: 'Entry Mode',
    selector: (row: Authorization) => getEntryModeByCode(row.entryMode),
    export: (row: Authorization) => getEntryModeByCode(row.entryMode),
  },
  {
    id: 'transactionDate',
    numeric: false,
    sortable: true,
    label: 'Date',
    info: selectedMid && (
      <TimeZonedDate
        timeZoneInfo={timeZoneInfo}
        setTimeZone={setTimeZone}
        merchantTimeZoneExists={merchantTimeZoneExists}
      />
    ),
    selector: row => getFormattedTransactionDate(row.transactionDate, timeZoneInfo.timeZoneId),
    export: row => getFormattedTransactionDate(row.transactionDate, timeZoneInfo.timeZoneId),
  },
  {
    id: 'authorizationResponse',
    numeric: false,
    sortable: true,
    label: 'Status',
    selector: getFormattedDisplayStatus,
    export: getFormattedDisplayStatus,
  },
];
